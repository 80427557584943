<template>
  <v-card
    class="card-custom-shadow"
    v-if="history.orders && history.orders.length > 0"
  >
    <v-card-text>
      <v-simple-table fixed-header class="table-padding-2-no-top table-h-30">
        <template v-slot:default>
          <tbody>
            <tr class="primary--text">
              <td>{{ $t("labels.warehouse_status") }}</td>
              <td>{{ $t("labels.created") }}</td>
              <td class="text-right">
                {{ history.total }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr class="primary--text">
              <td>{{ $t("labels.warehouse_status") }}</td>
              <td>{{ $t("labels.order_status_220_1") }}</td>
              <td class="text-right">
                {{ history.total_handover }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr class="primary--text">
              <td>{{ $t("labels.warehouse_status") }}</td>
              <td>{{ $t("labels.order_status_220") }}</td>
              <td class="text-right">
                {{ history.total_receipt_return }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr class="primary--text">
              <td>{{ $t("labels.warehouse_status") }}</td>
              <td>{{ $t("labels.order_status_240") }}</td>
              <td class="text-right">
                {{ history.total_return }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr class="purple--text">
              <td>{{ $t("labels.delivery_state") }}</td>
              <td>{{ $t("labels.order_delivering") }}</td>
              <td class="text-right">
                {{ history.total_delivering }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr class="purple--text">
              <td>{{ $t("labels.delivery_state") }}</td>
              <td>{{ $t("labels.order_wait_return") }}</td>
              <td class="text-right">
                {{ history.total_wait_return }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr class="purple--text">
              <td>{{ $t("labels.delivery_state") }}</td>
              <td>{{ $t("labels.order_success") }}</td>
              <td class="text-right">
                {{ history.total_success }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr class="error--text">
              <td>{{ $t("labels.no_delivery_connection") }}</td>
              <td>{{ $t("labels.no_status") }}</td>
              <td class="text-right">
                {{ history.total_no_status }} {{ $t("labels.order_2") }}
              </td>
            </tr>
            <tr>
              <td colspan="3" class="text-center">
                {{ $t("labels.recent_orders") }}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <v-simple-table fixed-header class="table-padding-2-no-top">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">{{ $t("labels.tracking") }}</th>
                        <th class="text-center">{{ $t("labels.cod") }}</th>
                        <th class="text-center">
                          {{ $t("labels.warehouse_status") }}
                        </th>
                        <th class="text-center">
                          {{ $t("labels.delivery_status") }}
                        </th>
                        <th class="text-center">
                          {{ $t("labels.delivery_state") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="hOrder in history.orders"
                        :key="`history_${hOrder.tracking_id}`"
                        class="text-center"
                      >
                        <td>
                          <OrderTracking :tracking-id="hOrder.tracking_id" />
                        </td>
                        <td>{{ formatNumber(hOrder.cash_on_delivery) }}</td>
                        <td>{{ $t(`labels.order_status_${hOrder.state}`) }}</td>
                        <td>{{ hOrder.delivery_partner_state }}</td>
                        <td>{{ hOrder.delivery_state_name }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "HistoryByPhone",
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
  },
  props: {
    phone: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    history: {},
  }),
  computed: {},
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      if (!this.isPhoneValid(this.phone)) {
        return false;
      }
      httpClient
        .post("/get-history-by-phone", { phone: this.phone })
        .then(({ data }) => {
          this.history = { ...data };
        });
    },
  },
};
</script>

<style scoped></style>
